/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&family=Poppins:wght@300;400;600&family=Roboto:wght@300;400;700&display=swap');

/* Apply global styles to html and body */
html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', 'Roboto', 'Merriweather', sans-serif;
  color: #333;
  background-color: #f9f9f9;
}

/* Headings with different fonts for emphasis */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Merriweather', serif;
  color: #222;
  margin-bottom: 12px;
  line-height: 1.4;
}

h1 {
  font-size: 36px;
  font-weight: 700;
}

h2 {
  font-size: 30px;
  font-weight: 600;
}

h3 {
  font-size: 24px;
  font-weight: 500;
}

/* Paragraphs and text content */
p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 16px;
}

/* Links */
a {
  color: #1a73e8;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
}

a:hover {
  text-decoration: underline;
}

/* Buttons */
button {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  background-color: #1a73e8;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #1558b6;
}

/* Lists */
ul, ol {
  font-family: 'Roboto', sans-serif;
  margin: 20px 0;
  padding-left: 40px;
}

li {
  margin-bottom: 10px;
}

/* Forms */
input, textarea {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 12px;
}

input:focus, textarea:focus {
  border-color: #1a73e8;
  outline: none;
}

/* Footer */
footer {
  font-family: 'Merriweather', serif;
  font-size: 14px;
  text-align: center;
  color: #888;
  padding: 20px 0;
  background-color: #333;
  color: #fff;
}
